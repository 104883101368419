import cx from "classnames";
import ButtonComponent from "@buildappeal/react-component-library/dist/Button";

const APPEARANCE = {
  primary: "primary",
  secondary: "secondary",
  accent: "accent",
};

const SIZE = {
  xs: "xs",
  sm: "sm",
  lg: "lg",
};

const Button = ({ appearance = APPEARANCE.primary, size = SIZE.xs, className, ...rest }) => {
  const classNameAppearance = {
    [APPEARANCE.secondary]:
      "!bg-aman hover:!bg-aman active:!bg-aman !border-neutral-400 hover:!border-neutral-700 active:!border-neutral-700",
  };

  const classNameSize = {
    [SIZE.xs]: "!h-10",
    [SIZE.sm]: "!h-12",
    [SIZE.lg]: "!h-14",
  };

  return (
    <ButtonComponent
      {...rest}
      appearance={appearance === APPEARANCE.primary ? APPEARANCE.accent : appearance}
      size={size}
      className={cx("rounded-full", classNameAppearance[appearance], classNameSize[size], className)}
    />
  );
};

export default Button;
