import React from "react";
import cx from "classnames";
import Picture from "components/picture";
import Counter from "components/counter";

const HeroSpacesList = ({ spaces, answer, isGrid, readOnly = false, handleSelectSpace, className }) => {
  return (
    <div
      className={cx(
        "mt-4",
        isGrid ? "mx-auto grid max-w-[34rem] grid-cols-3 lg:grid-cols-4" : "flex flex-wrap items-center justify-center",
        className
      )}
    >
      {spaces?.map((option, index) => (
        <div
          key={index}
          className={cx(
            "flex flex-col items-center rounded-xl px-3 py-2",
            readOnly ? (answer?.[option.space.id]?.value || 0) <= 0 && "hidden" : "cursor-pointer"
          )}
        >
          <div
            data-cy="space-option"
            onClick={() => !readOnly && !answer?.[option.space.id]?.value > 0 && handleSelectSpace(option.space, 1)}
            className="text-center"
          >
            <div
              className={cx(
                "flex h-[6rem] w-[6rem] shrink-0 items-center justify-center rounded-full border border-neutral-300 bg-[#F5F1E4] ring-accent-700 hover:ring-2",
                answer?.[option.space.id]?.value > 0 && "ring-2 ring-accent-700",
                answer?.[option.space.id]?.value > 0 && !readOnly && "!bg-transparent "
              )}
            >
              <Picture
                height={36}
                width={36}
                layout="fixed"
                objectFit="contain"
                placeholder="empty"
                picture={{ image: option.image.asset }}
              />
            </div>
            <span className="text-center text-xs font-medium text-neutral-700">
              {option.space.spaceName} {readOnly && ` (${answer?.[option.space.id]?.value})`}
            </span>
          </div>

          {!readOnly ? (
            <div data-cy="space-counter" className={answer?.[option.space.id]?.value > 0 ? "opacity-1" : "opacity-0"}>
              <Counter
                size="sm"
                className="!justify-center !py-0"
                value={answer?.[option.space.id]?.value || 0}
                onChange={(value) => handleSelectSpace(option.space, value)}
                min={0}
                max={10}
              />
            </div>
          ) : null}
        </div>
      ))}
    </div>
  );
};

export default HeroSpacesList;
