import React, { forwardRef } from "react";
import { useFullScreen } from "utils/helpers";

const FullScreen = forwardRef(({ style, diff = 0, ...other }, ref) => {
  const height = useFullScreen();

  const heightWithDiff = height ? `${height - diff}px` : `calc(100vh - ${diff}px)`;
  const heightWithoutDiff = height ? `${height}px` : "100vh";

  const styleWithRealHeight = {
    ...style,
    height: diff > 0 ? heightWithDiff : heightWithoutDiff,
  };

  return <div ref={ref} style={styleWithRealHeight} {...other} />;
});

FullScreen.displayName = "FullScreen";

export default FullScreen;
