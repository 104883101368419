import { useEffect } from "react";
import { useInView } from "react-cool-inview";
import noop from "lodash/noop";
import cx from "classnames";

import Button from "components/button";
import FlowTitleSummary from "./flow-title-summary";
import FlowSpaces from "./flow-spaces";
import FlowMedia from "./flow-media";

const FlowIntro = ({
  id,
  onChangeVisibility = noop,
  spaces,
  onShowEditSpaces = noop,
  title,
  description,
  bigMedia,
  checks,
}) => {
  const { observe, inView: observerIsVisible } = useInView({
    threshold: 1,
  });

  useEffect(() => {
    onChangeVisibility({ id, isVisible: observerIsVisible });
  }, [observerIsVisible]);

  return (
    <div className="flex min-h-[50vh] flex-col items-center pb-4 lg:pb-40" ref={observe}>
      <div className={cx("relative w-full overflow-hidden lg:hidden", bigMedia?.picture && "h-[280px]")}>
        <FlowMedia bigMedia={bigMedia} classNameVideo="h-full w-full bg-white video-cover" />
      </div>
      <div className="px-6 py-7">
        <FlowTitleSummary title={title} description={description} items={checks} />
        {spaces?.length > 0 ? (
          <div className="mt-12 flex flex-col items-center text-center lg:mt-20">
            <h3 className="mb-4 text-lg font-medium">Spaces in your package</h3>
            <FlowSpaces spaces={spaces} onEditClick={onShowEditSpaces} />
          </div>
        ) : (
          <div className="mt-12 flex flex-col rounded-xl bg-[#EEEEE2] px-8 py-12 text-center lg:mt-20">
            <span className="mb-2.5 inline-block text-3xl">Get started</span>
            <p className="mb-5 text-sm">
              To get started building your package, add the spaces you’re looking to get designed.
            </p>
            <Button label="Add spaces" onClick={onShowEditSpaces} />
          </div>
        )}
      </div>
    </div>
  );
};

export default FlowIntro;
