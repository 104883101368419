import PropTypes from "prop-types";
import cx from "classnames";
import { UilCheck } from "@iconscout/react-unicons";

const FlowTitleSummary = ({ title, description, items = [], className = "" }) => {
  return (
    <div className={cx("text-center text-accent-700", className)}>
      <h2 className="mb-4 text-4xl lg:text-5xl">{title}</h2>
      <p className="mb-6 text-sm">{description}</p>

      <div className="inline-flex gap-x-6 text-sm">
        {items.map((item, index) => (
          <div key={index} className="inline-flex items-center gap-x-2">
            <UilCheck className="h-4 w-4 shrink-0 rounded-full bg-accent-700 text-white" />
            <span>{item}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

FlowTitleSummary.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default FlowTitleSummary;
