import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { UilAngleLeft as BackIcon } from "@iconscout/react-unicons";
import { Dialog } from "@headlessui/react";

import Button from "components/button";
import Logo from "components/logo";
import colors from "utils/colors";
import useSpacesFromSanity from "hooks/useSpacesFromSanity";
import HeroSpacesList from "components/modules/hero-space-selection/hero-spaces-list";
import "components/modules/hero-space-selection/hero-space-selection.css";
import FullScreen from "components/full-screen";

const ModalSelectSpace = ({ title, description, selectedSpaces, onSave, isOpen, onClose, buttonLabel }) => {
  const [showErr, setShowErr] = useState(false);
  const [answer, setAnswer] = useState(selectedSpaces || {});

  const { data: spaces } = useSpacesFromSanity();

  const handleSelectSpace = (space, value) => {
    if (showErr) setShowErr(false);
    if (value > 0) {
      const newAnswer = {
        ...answer,
        [space.id]: {
          id: space.id,
          name: space.spaceName,
          value,
        },
      };
      setAnswer(newAnswer);
    } else {
      delete answer[space.id];
      setAnswer({ ...answer });
    }
  };

  const handleSave = () => {
    if (Object.keys(answer).length === 0) {
      setShowErr(true);
      return;
    }

    onClose();
    onSave(answer);
  };

  useEffect(() => {
    if (Object.keys(answer || {}).length === 0) {
      setAnswer(selectedSpaces);
    }
  }, [selectedSpaces]);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <FullScreen className="fixed inset-0 z-30 bg-aman">
        <div className="sticky top-0 h-[3.5rem] border-b border-neutral-200 py-3 px-3 lg:px-8">
          <div className="relative mx-auto flex h-full items-center justify-between md:max-w-[1280px] lg:justify-start xl:max-w-[1480px]">
            <BackIcon size={24} color={colors.blacktext} onClick={onClose} className="cursor-pointer" />
            <Logo color="#0A1A1F" className="lg:ml-5" />
            <span className="w-6 shrink-0" />
          </div>
        </div>
        <div className="h-[calc(100%_-_8rem)] overflow-y-auto py-10 px-6 scrollbar-hide">
          <div className="mx-auto max-w-xl">
            <div className="mb-10">
              <div className="text-center text-4xl font-normal text-accent-700 lg:text-5xl">{title}</div>
              {description && (
                <div className="mt-6 px-4 text-center text-md font-normal text-accent-700 lg:px-0">{description}</div>
              )}
              {showErr && (
                <p className="mt-4 text-center text-xs font-medium text-red-700 lg:text-sm">
                  Select at least one space to continue
                </p>
              )}
            </div>
            <HeroSpacesList isGrid spaces={spaces} answer={answer} handleSelectSpace={handleSelectSpace} />
          </div>
        </div>
        <div className="sticky bottom-0 flex items-center justify-end border-t border-neutral-200 bg-aman py-4 px-8">
          <Button className="h-12 !px-8 lg:h-14" label={buttonLabel} onClick={handleSave} size="md" />
        </div>
      </FullScreen>
    </Dialog>
  );
};

ModalSelectSpace.propTypes = {
  data: PropTypes.shape({
    theme: PropTypes.shape({
      value: PropTypes.oneOf(["light", "soft", "dark"]),
    }),
    isTop: PropTypes.shape({
      isTop: PropTypes.string,
    }),
    typedText: PropTypes.shape({
      leftText: PropTypes.string,
      rightText: PropTypes.string,
      typedText: PropTypes.string,
      strings: PropTypes.arrayOf(PropTypes.string),
    }),
    modules: PropTypes.arrayOf(
      PropTypes.shape({
        _type: PropTypes.string,
      })
    ),
  }),
};

export default ModalSelectSpace;
