import { Dialog } from "@headlessui/react";
import { UilMultiply } from "@iconscout/react-unicons";
import IconButton from "@buildappeal/react-component-library/dist/IconButton";

import useModalClearFix from "hooks/useModalClearFix";

const FlowModal = ({ children, isOpen, onClose }) => {
  useModalClearFix();

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className="fixed inset-0 z-30 bg-black/70" />
      <div className="fixed inset-0 z-30 mx-3 flex items-center justify-center overflow-y-auto">
        <Dialog.Panel className="relative z-40 w-full max-w-[64.5rem]">
          <IconButton
            icon={UilMultiply}
            color="white"
            className="absolute -top-10 right-0 border-0 lg:left-0"
            onClick={onClose}
          />
          <div className="relative overflow-hidden rounded-xl bg-white lg:rounded-3xl">{children}</div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default FlowModal;
