import Link from "next/link";

const PreviewMode = () => {
  return (
    <div className="w-full bg-accent-300 py-2 text-center">
      <span className="px-1">&#128161; Preview mode activated! &#128161;</span>
      <Link href="/api/exit-preview">
        <a className="text-blue-600 hover:text-blue-800 visited:text-purple-600 underline">End Preview</a>
      </Link>
    </div>
  );
};

export default PreviewMode;
