import dynamic from "next/dynamic";

const Hero = dynamic(() => import("./hero"));
const HeroGrid = dynamic(() => import("./hero-grid"));
const ImageText = dynamic(() => import("./image-text"));
const FeaturedThree = dynamic(() => import("./featured-three"));
const LeftTitleRightText = dynamic(() => import("./left-title-right-text"));
const BigText = dynamic(() => import("./big-text"));
const BlurbTwoLists = dynamic(() => import("./blurb-two-lists"));
const HeadSub = dynamic(() => import("./head-sub"));
const FaqDisplay = dynamic(() => import("./faq-display"), { ssr: false });
const Freeform = dynamic(() => import("./freeform"));
const ProjectHeroPhoto = dynamic(() => import("./project-hero-photo"));
const HeroSimple = dynamic(() => import("./hero-simple"));
const Testimonials = dynamic(() => import("./testimonials"));
const Gallery = dynamic(() => import("./gallery"));
const HorizontalMasonry = dynamic(() => import("./horizontal-masonry"));
const BeforePlusAfterSlider = dynamic(() => import("../before-plus-after-slider"));
const Quote = dynamic(() => import("../blocks/quote"));
const Logos = dynamic(() => import("./logos"));
const Numbers = dynamic(() => import("./numbers"));
const Squares = dynamic(() => import("./squares"));
const Review = dynamic(() => import("./review"));
const DesignStyle = dynamic(() => import("./design-style"));
const Video = dynamic(() => import("./video"));
const ImageCard = dynamic(() => import("./image-card"));
const ReviewSlider = dynamic(() => import("./review-slider"));
const Consultation = dynamic(() => import("./consultation"));
const YourProject = dynamic(() => import("./your-project"));
const MySpaces = dynamic(() => import("./my-spaces"));
const NextStep = dynamic(() => import("./next-step"));
const PrimaryFeatures = dynamic(() => import("../PrimaryFeatures"));
const Comparison = dynamic(() => import("./comparison"));
const HeroSpaceSelection = dynamic(() => import("./hero-space-selection"));
const ShowCase = dynamic(() => import("./showcase"));

export const Module = ({ index, module }) => {
  const type = module?._type;

  switch (type) {
    case "hero":
      return <Hero index={index} data={module} />;
    case "heroGrid":
      return <HeroGrid index={index} data={module} />;
    case "logos":
      return <Logos index={index} data={module} />;
    case "imageText":
      return <ImageText index={index} data={module} />;
    case "featuredThree":
      return <FeaturedThree index={index} data={module} />;
    case "numbers":
      return <Numbers index={index} data={module} />;
    case "squares":
      return <Squares index={index} data={module} />;
    case "primaryFeatures":
      return <PrimaryFeatures index={index} data={module} />;
    case "headSub":
      return <HeadSub index={index} data={module} />;
    case "bigText":
      return <BigText index={index} data={module} />;
    case "leftTitleRightText":
      return <LeftTitleRightText index={index} data={module} />;
    case "blurbTwoLists":
      return <BlurbTwoLists index={index} data={module} />;
    case "faqDisplay":
      return <FaqDisplay index={index} data={module} />;
    case "projectHeroPhoto":
      return <ProjectHeroPhoto index={index} data={module} />;
    case "freeform":
      return <Freeform index={index} data={module} />;
    case "heroSimple":
      return <HeroSimple index={index} data={module} />;
    case "testimonials":
      return <Testimonials index={index} data={module} />;
    case "gallery":
      return <Gallery index={index} data={module} />;
    case "horizontalMasonry":
      return <HorizontalMasonry index={index} data={module} />;
    case "beforePlusAfter":
      return <BeforePlusAfterSlider index={index} data={module} />;
    case "quote":
      return <Quote index={index} data={module} />;
    case "reviews":
      return <Review index={index} data={module} />;
    case "designStyles":
      return <DesignStyle index={index} data={module} />;
    case "video":
      return <Video index={index} data={module} />;
    case "carousel":
      return <ImageCard index={index} data={module} />;
    case "reviewSlider":
      return <ReviewSlider index={index} data={module} />;
    case "consultation":
      return <Consultation index={index} data={module} />;
    case "yourProject":
      return <YourProject index={index} data={module} />;
    case "nextStep":
      return <NextStep index={index} data={module} />;
    case "mySpaces":
      return <MySpaces index={index} data={module} />;
    case "comparison":
      return <Comparison index={index} data={module} />;
    case "heroSpaceSelection":
      return <HeroSpaceSelection index={index} data={module} />;
    case "showcase":
      return <ShowCase index={index} data={module} />;
    default:
      return null;
  }
};
