import { useState } from "react";

import Button from "components/button";
import ButtonGroup from "./button-group";
import Totals from "./totals";

const PAYMENT_OPTIONS = {
  half: "half",
  full: "full",
};

const PaymentSummary = ({ className, total }) => {
  const [activePayment, setActivePayment] = useState(PAYMENT_OPTIONS.half);

  const buttons = [
    {
      id: 1,
      title: "Pay a 50% deposit",
      isActive: activePayment === PAYMENT_OPTIONS.half,
      onClick: () => setActivePayment(PAYMENT_OPTIONS.half),
    },
    {
      id: 2,
      title: "Pay in Full",
      isActive: activePayment === PAYMENT_OPTIONS.full,
      onClick: () => setActivePayment(PAYMENT_OPTIONS.full),
    },
  ];

  return (
    <div className={className}>
      <ButtonGroup buttons={buttons} />

      <div className="my-5">
        <Totals label="Subtotal" value={total} showBorder />
        <Totals label="Total" value={total} size="lg" showBorder={activePayment === PAYMENT_OPTIONS.half} />
        {activePayment === PAYMENT_OPTIONS.half && (
          <Totals label="Due today" value={total / 2} size="lg" classNameValue="!text-green-600" />
        )}
      </div>

      <Button label="Continue payment" className="w-full" size="sm" />
    </div>
  );
};

export default PaymentSummary;
