import { useRouter } from "next/router";
import urlJoin from "proper-url-join";
import { getSlug } from "utils/page";
import Layout from "../components/layout";
import PreviewMode from "../components/preview-mode";
import { Module } from "components/modules";
import FlowPage from "components/flow/flow-page";
import { getClient } from "lib/sanity.server";
import { usePreviewSubscription } from "lib/sanity";
import { GET_ALL_STATIC_SLUGS_QUERY, GET_ALL_FLOW_SLUGS_QUERY, getPageDataByStaticSlug } from "../data";

const Route = ({ data: initialData, slug, preview }) => {
  const router = useRouter();
  const {
    error,
    loading,
    data: { page, site },
  } = usePreviewSubscription(getPageDataByStaticSlug(preview || process.env.NODE_ENV === "development"), {
    params: { slug },
    initialData,
    enabled: preview,
  });

  if (loading) {
    return <h4>loading...</h4>;
  }

  if (page._type === "flow") {
    return <FlowPage page={page} />;
  }

  return (
    <>
      {!loading && !router.isFallback && (
        <>
          {preview && <PreviewMode />}
          {error && <h4>Error: ${error?.message}</h4>}
          {site && page && (
            <Layout site={site} page={page}>
              {page.modules?.map((module, key) => (
                <Module key={key} index={key} module={module} />
              ))}
            </Layout>
          )}
        </>
      )}
    </>
  );
};

export const getStaticPaths = async () => {
  const staticPages = await getClient().fetch(GET_ALL_STATIC_SLUGS_QUERY);
  const flowPages = await getClient().fetch(GET_ALL_FLOW_SLUGS_QUERY);
  const staticPaths = staticPages.slugs.map((slug) => ({
    params: {
      slug: [urlJoin(slug, { leadingSlash: false })].filter(Boolean),
    },
  }));
  const flowPaths = flowPages.slugs.map((slug) => ({
    params: {
      slug: [urlJoin(slug, { leadingSlash: false })].filter(Boolean),
    },
  }));

  return { paths: [...staticPaths, ...flowPaths], fallback: "blocking" };
};

export const getStaticProps = async ({ params, preview = false }) => {
  const slug = getSlug(params) || "/";
  const data = await getClient(preview).fetch(
    getPageDataByStaticSlug(preview || process.env.NODE_ENV === "development"),
    { slug }
  );

  if (!data?.page) {
    return { notFound: true, revalidate: 60 };
  }
  return {
    props: { data, slug, preview },
    revalidate: 10,
  };
};

export default Route;
