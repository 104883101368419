import { useState, useEffect, useMemo } from "react";
import Link from "next/link";
import cx from "classnames";
import { UilAngleUp } from "@iconscout/react-unicons";
import IconButton from "@buildappeal/react-component-library/dist/IconButton";
import { v4 as uuidv4 } from "uuid";

import colors from "utils/colors";
import { formatCurrency } from "utils/helpers";
import Logo from "components/logo";
import Picture from "components/picture";
import Video from "components/video";
import LottieImage from "components/lottie-image";
import useServices from "hooks/useServices";
import useSpacesFromSanity from "hooks/useSpacesFromSanity";
import FlowSection from "./flow-section";
import FlowIntro from "./flow-intro";
import FlowOverview from "./flow-overview";
import ModalSelectSpace from "./modal-select-space";
import FlowModalSlider from "./flow-modal-slider";

const FlowPage = ({ page }) => {
  const { data: serviceList, isLoading: serviceLoading } = useServices();
  const { data: spaceList, isLoading: spaceLoading } = useSpacesFromSanity();

  const [currentSlide, setCurrentSlide] = useState(0);
  const [showOverview, setShowOverview] = useState(false);
  const [showModalSelectSpace, setShowModalSelectSpace] = useState(false);
  const [selectedSpaces, setSelectedSpaces] = useState({});
  const [slides, setSlides] = useState(page.slides || []);
  const [spaces, setSpaces] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [selectedSpaceList, setSelectedSpaceList] = useState([]);
  const [showInfoModals, setShowInfoModals] = useState(false);
  const [slideInfoModal, setSlideInfoModal] = useState(null);

  const introSlide = page.slides.find((item) => item._type === "flowSpaceSelection");

  const modalSliders = useMemo(() => {
    if (!slideInfoModal) return [];

    return slides.find((slide) => slide._key === slideInfoModal)?.modal?.[0]?.modalSlide;
  }, [slideInfoModal]);

  useEffect(() => {
    const spaceFiltered = spaceList.filter((item) => selectedSpaces[item.space.id]);
    const spacesUpdated = spaceFiltered
      .map((item) => {
        const selectedSpace = selectedSpaces[item.space.id];
        const list = Array.from(Array(selectedSpace.value).keys()).map((value, index) => {
          const title = `${selectedSpace.name} ${index === 0 ? "" : index + 1}`.trim();
          const space = spaces.find((spaceItem) => spaceItem.title === title) || {};

          return {
            ...item,
            ...space,
            uuid: space?.uuid || uuidv4(),
            title: space?.title || title,
          };
        });
        return list;
      })
      .flatMap((item) => item);

    setSelectedSpaceList(spaceFiltered);
    setSpaces(spacesUpdated);
  }, [spaceList, selectedSpaces]);

  const total = useMemo(() => {
    let final = 0;

    spaces.forEach((space) => {
      const subTotal =
        space.services?.reduce((acc, service) => {
          const serviceSpace = service.spaces.find((item) => item.space.id === space.space.id);
          return acc + (serviceSpace?.baseCost || 0);
        }, 0) || 0;

      final += subTotal;
    });

    selectedServices.forEach((service) => {
      final += service.baseCost || 0;
    });

    return final;
  }, [spaces, selectedServices]);

  const spacesNames = useMemo(() => {
    const firstThreeSpaces = spaces
      .slice(0, 3)
      .map((space) => space.space.spaceName)
      .join(", ");

    if (spaces.length <= 3) return firstThreeSpaces;

    return `${firstThreeSpaces} +${spaces.length - 3} more`;
  }, [spaces]);

  const handleVisibility = ({ id, isVisible }) => {
    if (isVisible) {
      setCurrentSlide(id);
    }
  };

  const handleChangeSelectedSpaces = (spaceUuids, service) => {
    const spacesUpdated = spaces.map((space) => {
      if (spaceUuids.includes(space.uuid)) {
        const index = space.services?.findIndex((item) => {
          return item.id === service.id;
        });

        const services =
          index === -1 || index === undefined
            ? [...(space.services || []), service]
            : space.services?.filter((item) => item.id !== service.id);

        return {
          ...space,
          services,
        };
      }
      return space;
    });

    setSpaces(spacesUpdated);
  };

  const handleChangeSelectedService = (service) => {
    const index = selectedServices.findIndex((item) => item.id === service.id);
    const slide = slides.find((item) => item.linkedService?.id === service.id) || {};

    const services =
      index === -1
        ? [...selectedServices, { ...service, slide }]
        : selectedServices.filter((item) => item.id !== service.id);

    setSelectedServices(services);
  };

  const handleSaveSpaces = (selectedSpacesUpdated) => {
    setSelectedSpaces(selectedSpacesUpdated);
    window.localStorage.setItem("designPackageAnswer", JSON.stringify(selectedSpacesUpdated));
  };

  useEffect(() => {
    if (!serviceList?.length || Object.keys(selectedSpaces).length === 0) {
      return;
    }

    const slidesWithService = page.slides
      .filter((item) => item._type === "flowSlide")
      .map((item) => {
        const service = serviceList.find((service) => service.id === item.linkedService?.id);
        const serviceSpaces = service?.spaces
          ?.filter((serviceSpace) => {
            return selectedSpaces[serviceSpace.space.id];
          })
          ?.map((serviceSpace) => {
            return {
              ...serviceSpace,
              ...(spaceList.find((item) => item.space.id === serviceSpace.space.id) || {}),
            };
          });

        return {
          ...item,
          service: {
            ...service,
            spaces: serviceSpaces,
          },
        };
      });

    setSlides(slidesWithService);
  }, [serviceList, selectedSpaces]);

  useEffect(() => {
    const designPackageAnswer = window.localStorage.getItem("designPackageAnswer");

    if (!designPackageAnswer) return;

    setSelectedSpaces(JSON.parse(designPackageAnswer));
  }, []);

  return (
    <div className="!scroll-smooth">
      <header className="sticky top-0 z-10 h-0">
        <div className="flex h-14 items-center justify-between border-b border-neutral-200 bg-aman/75 py-3 px-6 lg:px-10">
          <Link href="/">
            <a>
              <Logo color={colors.green} />
            </a>
          </Link>
          <span className="text-xs">
            Call or text us{" "}
            <a className="font-medium underline" href="tel:+13235535563">
              (323) 553-5563
            </a>
          </span>
        </div>
      </header>
      <main className="scroll-mt-14">
        <div className="lg:grid lg:grid-cols-[9fr_394px] lg:grid-rows-[1fr] lg:gap-x-12 lg:pr-12">
          <div className="sticky top-0 hidden h-screen max-h-full w-full overflow-hidden lg:block">
            {introSlide && (
              <div
                className={cx(
                  "absolute top-0 left-0 flex h-full w-full items-center justify-center bg-aman transition-all duration-500",
                  currentSlide === introSlide._key ? "visible opacity-100" : "invisible opacity-0"
                )}
                key={introSlide._key}
              >
                {introSlide.bigMedia?.picture && (
                  <Picture picture={{ image: introSlide.bigMedia.picture }} layout="fill" objectFit="cover" />
                )}
                {introSlide.bigMedia?.video && (
                  <Video video={introSlide.bigMedia.video} className="video-cover h-full w-full" autoPlay muted loop />
                )}
                {introSlide.bigMedia?.bigLottieFile && (
                  <LottieImage lottieFile={introSlide.bigMedia?.bigLottieFile} width={null} height={null} />
                )}
              </div>
            )}
            {slides.map((slide) => (
              <div
                className={cx(
                  "absolute top-0 left-0 flex h-full w-full items-center justify-center bg-aman transition-all duration-500",
                  currentSlide === slide._key ? "visible opacity-100" : "invisible opacity-0"
                )}
                key={slide._key}
              >
                {slide.bigMedia?.picture && (
                  <Picture picture={{ image: slide.bigMedia.picture }} layout="fill" objectFit="cover" />
                )}
                {slide.bigMedia?.video && (
                  <Video video={slide.bigMedia.video} className="video-cover h-full w-full" autoPlay muted loop />
                )}
                {slide.bigMedia?.bigLottieFile && (
                  <LottieImage lottieFile={slide.bigMedia?.bigLottieFile} width={null} height={null} />
                )}
              </div>
            ))}
          </div>
          <div className="my-14 pb-10 lg:pt-14">
            <div className="mb-40 flex flex-col items-center text-center">
              <FlowIntro
                id={introSlide?._key}
                onChangeVisibility={handleVisibility}
                spaces={selectedSpaceList}
                onShowEditSpaces={() => setShowModalSelectSpace(true)}
                title={introSlide?.title}
                description={introSlide?.description}
                bigMedia={introSlide?.bigMedia}
                checks={introSlide?.checks}
              />
            </div>
            {slides?.map((slide) => (
              <FlowSection
                key={slide._key}
                id={slide._key}
                onChangeVisibility={handleVisibility}
                title={slide.title}
                description={slide.description}
                bigMedia={slide.bigMedia}
                spaces={spaces}
                onChangeSelectedSpaces={handleChangeSelectedSpaces}
                onChangeSelectedService={handleChangeSelectedService}
                service={slide.service}
                isServiceSelected={selectedServices.some((item) => item.id === slide.service?.id)}
                isLoading={serviceLoading || spaceLoading}
                onShowInfoModal={() => {
                  setSlideInfoModal(slide._key);
                  setShowInfoModals(true);
                }}
              />
            ))}
          </div>
        </div>
      </main>
      {total > 0 && (
        <footer className="fixed bottom-0 z-10 flex w-full justify-center lg:w-[calc(100%_-_494px)]">
          <button
            className="flex w-full max-w-md cursor-pointer items-center justify-between gap-x-4 rounded-t-xl bg-accent-700 p-5 text-white"
            onClick={() => setShowOverview(true)}
          >
            <span className="flex items-center gap-x-3">
              <IconButton icon={UilAngleUp} color="white" size="sm" />
              <span className="text-xl lg:text-2xl">{formatCurrency(total)}</span>
              <span className="text-xs">{spacesNames}</span>
            </span>
            <span className="text-xs font-medium underline">See details</span>
          </button>
        </footer>
      )}
      <FlowOverview
        isOpen={showOverview}
        onClose={() => setShowOverview(false)}
        spaces={spaces}
        spaceList={selectedSpaceList}
        selectedServices={selectedServices}
        total={total}
        onShowEditSpaces={() => setShowModalSelectSpace(true)}
      />
      <ModalSelectSpace
        title="What spaces do you need designs for?"
        isOpen={showModalSelectSpace}
        onClose={() => setShowModalSelectSpace(false)}
        selectedSpaces={selectedSpaces}
        buttonLabel="Save and continue"
        onSave={handleSaveSpaces}
      />
      <FlowModalSlider isOpen={showInfoModals} onClose={() => setShowInfoModals(false)} sliders={modalSliders} />
    </div>
  );
};

export default FlowPage;
