import { gql } from "graphql-request";
import graphQLClient from "lib/graphqlClient";

const GET_PROJECTS = gql`
  query getClientProjects($clientEmail: String!) {
    getClientProjects(clientEmail: $clientEmail) {
      id
      projectedStart
      projectedCompletion
      budgets {
        id
      }
      clients {
        id
        fullName
        firstName
        lastName
        email
        phone
        avatar
        type
        isPrimaryContact
      }
      spaces {
        id
        spaceId
        name
        location
      }
      brands {
        name
        url
        email
        phone
        description
      }
      contracts {
        id
        contractType
        document_id
        lastUpdated
        createdAt
      }
      projectStages {
        id
        name
        stageType
        stageStatus
      }
      places {
        addressLine1
        addressLine2
        city
        state
        zipCode
        latitude
        longitude
      }
      staff {
        id
        firstName
        lastName
        fullName
        email
        avatar
        projectCount
        yearsExperience
        signature
      }
      consultations {
        consultationDate
      }
    }
  }
`;

const GET_PROJECT_BY_ID = gql`
  query getClientProjectByID($projectId: Int!) {
    getClientProjectByID(id: $projectId) {
      id
      projectedStart
      projectedCompletion
      projectStages {
        name
        stageType
        order
      }
      staff {
        fullName
        avatar
      }
      clients {
        id
        fullName
        firstName
        lastName
        email
        phone
        avatar
        type
        isPrimaryContact
      }
      contracts {
        name
        contractType
        status
        lastUpdated
        staff {
          id
          fullName
          avatar
        }
      }
      places {
        addressLine1
        city
        state
        zipCode
      }
    }
  }
`;

const GET_SPACES = gql`
  query getSpaces($filters: SpaceFilterFields!) {
    getSpaces(filters: $filters) {
      id
      name
      location
      sortOrder
    }
  }
`;

const GET_PRESENTATIONS = gql`
  query getPresentations($filters: PresentationFilterFields!) {
    getPresentations(filters: $filters) {
      id
      createdAt
      status
      versionNumber
      videoIntro
    }
  }
`;

const GET_PACKAGES = gql`
  query getPackages($filters: PackageFilterFields!) {
    getPackages(filters: $filters) {
      id
      name
      packageSpaces {
        id
        baseCost
        space {
          id
          name
        }
      }
      packageServices {
        id
        sortOrder
        service {
          id
          name
          description
        }
      }
    }
  }
`;

const CREATE_PROJECT = gql`
  mutation createProject($input: CreateProjectRequest!) {
    createProject(input: $input) {
      code
      success
      message
      data {
        id
        color
        projectedStart
        projectedCompletion
        accessDifficulty
        lockboxCode
        dateCreated
        clients {
          id
          fullName
          email
          avatar
        }
        staff {
          id
          fullName
          email
          avatar
          isOwner
        }
        spaces {
          id
          name
          thumbnail {
            id
            path
          }
          measurementsWidth
          measurementsLength
          sqft
          roomNumber
          spaceNickname
          floor
          spaceId
          permitRequired
          isLikeForLike
          parentSpaceId
          sortOrder
          budget
        }
        projectStages {
          id
          name
          stageType
          order
        }
        places {
          id
          addressLine1
          city
          state
          zipCode
          latitude
          longitude
          stories
        }
        thumbnail {
          id
          path
        }
        leadSource
        leadSourceLink
        type
        designStyles {
          id
          name
        }
      }
    }
  }
`;

const GET_SERVICES = gql`
  query getServices($filters: ServiceFilterFields!) {
    getServices(filters: $filters) {
      id
      name
      baseCost
      spaces {
        id
        baseCost
        space {
          id
        }
      }
    }
  }
`;

export const fetchProjects = async (clientEmail) => {
  return await graphQLClient.request(GET_PROJECTS, { clientEmail });
};

export const fetchProjectById = async (projectId) => {
  return await graphQLClient.request(GET_PROJECT_BY_ID, { projectId });
};

export const fetchSpaces = async () => {
  const filters = {};

  return await graphQLClient.request(GET_SPACES, { filters });
};

export const fetchPresentations = async (filters) => {
  return await graphQLClient.request(GET_PRESENTATIONS, { filters });
};

export const fetchPackages = async (filters) => {
  return await graphQLClient.request(GET_PACKAGES, { filters });
};

export const createProject = async (input = {}) => {
  return await graphQLClient.request(CREATE_PROJECT, { input });
};

export const fetchServices = async (filters = {}) => {
  return await graphQLClient.request(GET_SERVICES, { filters });
};
