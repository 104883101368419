import { useQuery } from "react-query";

import { fetchServices } from "services/projects/api";

const useServices = () => {
  const { data, isLoading } = useQuery("services", () => fetchServices(), {
    refetchOnWindowFocus: false,
  });

  return {
    data: data?.getServices,
    isLoading,
  };
};

export default useServices;
