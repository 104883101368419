import cx from "classnames";

const ButtonGroup = ({ buttons, className }) => (
  <div className={cx("flex w-full gap-x-0.5 bg-[#EEEEE2] p-2", className)}>
    {buttons.map((button) => (
      <button
        key={button.id}
        className={cx(
          "flex-1 rounded py-3 px-0.5 text-sm font-medium text-accent-700 lg:text-md",
          button.isActive ? "bg-white shadow-md" : "hover:bg-[#E8E8D9]"
        )}
        onClick={button.onClick}
      >
        {button.title}
      </button>
    ))}
  </div>
);

export default ButtonGroup;
