import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { UilCheck as CheckIcon } from "@iconscout/react-unicons";
import noop from "lodash/noop";

const Checkbox = ({ disabled = false, className = "", mRef, checked, onChange = noop, children, ...rest }) => {
  const clsChildren = "min-w-[24px] max-h-6 w-6 h-6 min-h-6 border rounded";

  return (
    <label data-testid="Checkbox" className={clsx("relative", "flex", className)} ref={mRef}>
      {/* Hidden input with checkbox type */}
      <input
        data-testid="Checkbox-input"
        className={clsx(clsChildren, "peer sr-only hidden")}
        type="checkbox"
        disabled={disabled}
        onChange={onChange}
        checked={checked}
        {...rest}
      />
      {/* Icon */}
      <CheckIcon
        data-testid="Checkbox-icon"
        className={clsx(
          clsChildren,
          "mr-3",
          "hidden peer-checked:flex",
          "border-primary-700 bg-primary-700 text-neutral-100",
          "peer-hover:border-neutral-600",
          "peer-disabled:border-none peer-disabled:bg-neutral-300 peer-disabled:text-neutral-400"
        )}
      />
      <div
        className={clsx(
          clsChildren,
          "mr-3",
          "flex peer-checked:hidden",
          "dark:bg-midnight border-neutral-300 bg-white dark:border-neutral-500",
          "peer-hover:border-neutral-600",
          "peer-disabled:border-none peer-disabled:bg-neutral-200"
        )}
      />
      {children}
    </label>
  );
};

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  mRef: PropTypes.shape({ current: PropTypes.any }),
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Checkbox;
