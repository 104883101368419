import { useEffect, useMemo } from "react";
import { useInView } from "react-cool-inview";
import keyBy from "lodash/keyBy";
import noop from "lodash/noop";
import cx from "classnames";
import { UilCheck } from "@iconscout/react-unicons";

import Checkbox from "components/checkbox";
import Button from "components/button";
import BlockContent from "components/blocks/block-content";
import FlowMedia from "./flow-media";
import { formatCurrency } from "utils/helpers";

const FlowSection = ({
  id,
  onChangeVisibility = noop,
  title,
  description,
  bigMedia,
  onChangeSelectedSpaces = noop,
  onChangeSelectedService = noop,
  spaces = [],
  service,
  isServiceSelected,
  isLoading,
  onShowInfoModal = noop,
}) => {
  const { observe, inView: observerIsVisible } = useInView({
    threshold: 1,
  });

  useEffect(() => {
    onChangeVisibility({ id, isVisible: observerIsVisible });
  }, [observerIsVisible]);

  const serviceSpaces = keyBy(service?.spaces, "space.id");
  const serviceHasSpaces = !!service?.spaces?.length;

  const spaceHasService = (spaceId) => {
    return !!serviceSpaces[spaceId];
  };

  const spacesWithService = useMemo(
    () =>
      spaces
        ?.filter((item) => spaceHasService(item.space.id))
        ?.map((item) => {
          return {
            ...item,
            isSelected: !!item.services?.find((serviceItem) => serviceItem.id === service.id),
          };
        }),
    [spaces, spaceHasService, service]
  );

  const allSpacesSelected = spacesWithService?.every((item) => item.isSelected);

  return (
    <div className="pt-4 pb-4 lg:min-h-[55vh] lg:pb-40" ref={observe}>
      <div className={cx("relative w-full overflow-hidden lg:hidden", bigMedia.picture && "h-[280px]")}>
        <FlowMedia bigMedia={bigMedia} classNameVideo="h-full w-full bg-white video-cover" />
      </div>

      <div className="relative z-[1] -mt-1.5 bg-aman px-6 py-12 lg:mt-0 lg:py-0">
        <div className="pb-2">
          <div className="mb-3 text-accent-700">
            <h3 className="text-center text-4xl font-normal lg:text-left lg:text-3xl">{title}</h3>
            {!serviceHasSpaces && <span className="text-sm">+ {formatCurrency(service?.baseCost || 0)}</span>}
          </div>

          <BlockContent blocks={description} className="hidden-list-icons mb-5 list-disc !text-sm" />
        </div>

        {!isLoading && (
          <div>
            {serviceHasSpaces ? (
              <div>
                <div className="flex gap-x-2">
                  <Button
                    className={cx(
                      "flex-1 transition-all duration-300 ease-out",
                      allSpacesSelected
                        ? "!border-accent-700 !bg-aman !text-accent-700 hover:!bg-aman active:!bg-aman"
                        : ""
                    )}
                    onClick={() => {
                      !allSpacesSelected &&
                        onChangeSelectedSpaces(
                          spacesWithService.filter((item) => !item.isSelected).map((item) => item.uuid),
                          service
                        );
                    }}
                    label={allSpacesSelected ? "Added" : "Add to all spaces"}
                    size="xs"
                    icon={allSpacesSelected ? UilCheck : null}
                  />
                  <Button
                    className="flex-1"
                    onClick={() => onShowInfoModal(id)}
                    label="Learn the benefits"
                    appearance="secondary"
                    size="xs"
                  />
                </div>

                <div className="space-y-3 pt-6">
                  {spacesWithService.map((item) => (
                    <div className="flex justify-between" key={item.uuid}>
                      <Checkbox
                        label={item.title}
                        onChange={() => onChangeSelectedSpaces([item.uuid], service)}
                        checked={!!item.services?.find((serviceItem) => serviceItem.id === service.id)}
                        className="flex w-full items-center"
                      >
                        <span className="flex w-full items-center justify-between">
                          <span className="text-sm font-medium text-accent-700">{item.title}</span>
                          <span className="text-sm text-neutral-600">
                            + {formatCurrency(serviceSpaces[item.space.id]?.baseCost || 0)}
                          </span>
                        </span>
                      </Checkbox>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div>
                <div className="flex gap-x-2">
                  <Button
                    className={cx(
                      "flex-1 transition-all duration-300 ease-out",
                      isServiceSelected
                        ? "!border-accent-700 !bg-aman !text-accent-700 hover:!bg-aman active:!bg-aman"
                        : ""
                    )}
                    onClick={() => {
                      !isServiceSelected && onChangeSelectedService(service);
                    }}
                    label={isServiceSelected ? "Added" : "Add"}
                    size="xs"
                    icon={isServiceSelected ? UilCheck : null}
                  />
                  <Button
                    className="flex-1"
                    onClick={() => onShowInfoModal(id)}
                    label="Learn the benefits"
                    appearance="secondary"
                  />
                </div>
                {isServiceSelected && (
                  <button
                    className="mt-3.5 inline-block cursor-pointer text-xs text-neutral-600 underline underline-offset-2"
                    onClick={() => {
                      onChangeSelectedService(service);
                    }}
                  >
                    Remove from package
                  </button>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FlowSection;
