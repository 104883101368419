import PropTypes from "prop-types";

import { formatCurrency } from "utils/helpers";
import Picture from "components/picture";
import Video from "components/video";
import LottieImage from "components/lottie-image";
import SpaceCircle from "./space-circle";

const CardLineItem = ({ title, total, items = [], showDetails = false, media, space }) => {
  return (
    <div className="flex items-start gap-x-6 border-b border-neutral-200 py-6">
      {space && <SpaceCircle image={space.image} size="sm" hideName />}
      {media && media?.picture && (
        <Picture
          picture={{ image: media.picture }}
          objectFit="cover"
          width={78}
          height={78}
          className="h-16 w-16 shrink-0 overflow-hidden rounded-full object-cover"
        />
      )}
      {media && media?.video && (
        <Video
          video={media.video}
          className="video-cover h-16 w-16 shrink-0 overflow-hidden rounded-full !object-cover"
          autoPlay
          muted
          loop
        />
      )}
      {media && media?.bigLottieFile && (
        <LottieImage lottieFile={media?.bigLottieFile} width={64} height={64} className="" />
      )}
      <div className="w-full text-accent-700">
        <div className="flex items-center justify-between text-md font-medium">
          <strong>{title}</strong>
          <strong>{formatCurrency(total)}</strong>
        </div>
        <div className="space-y-0.5">
          {items.map((item, index) => (
            <div className="flex items-center justify-between text-sm" key={index}>
              <span>{item.subTitle}</span>
              {showDetails && <span>{formatCurrency(item.subTotal)}</span>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

CardLineItem.propTypes = {
  title: PropTypes.string,
  total: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      subTitle: PropTypes.string,
      subTotal: PropTypes.number,
    })
  ),
  showDetails: PropTypes.bool,
};

export default CardLineItem;
