import MuxPlayer from "@mux/mux-player-react";
import cx from "classnames";

import "./video.css";

export default function Video({
  video,
  className,
  autoPlay = false,
  controls = true,
  loop = false,
  muted = false,
  playsInline = true,
  ...props
}) {
  if (!video?.asset) return null;

  return (
    <MuxPlayer
      playbackId={video.asset.playbackId}
      autoPlay={autoPlay}
      controls={controls}
      playsInline={playsInline}
      className={cx("mux-player", className)}
      muted={muted}
      loop={loop}
      {...props}
    />
  );
}
