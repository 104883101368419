import cx from "classnames";
import Button from "@buildappeal/react-component-library/dist/Button";

import SpaceCircle from "./space-circle";

const FlowSpaces = ({ spaces, onEditClick, className }) => {
  return (
    <div className={cx("flex flex-col items-center justify-center pb-5 lg:!flex", className)}>
      <div className="mb-7 flex flex-wrap justify-center gap-3">
        {spaces?.map((space) => (
          <SpaceCircle key={space.space.id} space={space.space} image={space.image} />
        ))}
      </div>
      <Button label="Edit spaces" onClick={onEditClick} appearance="tertiary" className="w-auto" />
    </div>
  );
};

export default FlowSpaces;
