import { useEffect, useState } from "react";
import { useQuery } from "react-query";

import { getClient } from "lib/sanity.server";
import { GET_QUIZZES } from "data/index";

const useSpacesFromSanity = () => {
  const [spaces, setSpaces] = useState([]);

  const { data, isLoading, error } = useQuery(
    "sanitySpaces",
    async () => await getClient(false).fetch(GET_QUIZZES, { slug: "onboarding" }),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (data) {
      const resSpaces = data?.quizzes?.questions?.find((q) => q._type === "quizQuestionSpace")?.questionAnswers;
      setSpaces(resSpaces);
    }
  }, [data]);

  return { data: spaces ?? [], isLoading, error };
};

export default useSpacesFromSanity;
