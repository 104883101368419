import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { UilPlus as PlusIcon, UilMinus as MinusIcon } from "@iconscout/react-unicons";

const Counter = ({ value = 0, step = 1, onChange, className, min = 0, max = 10, size = "lg" }) => {
  const containerIconClassName =
    "flex cursor-pointer items-center justify-center rounded-full border hover:border-black hover:text-black shrink-0";
  const containerIconSizeClassName = {
    sm: "h-6 w-6 shrink-0",
    md: "h-8 w-8 shrink-0",
    lg: "h-10 w-10 shrink-0",
  };
  const iconClassName = {
    sm: "h-2.5 w-2.5",
    md: "h-4 w-4",
    lg: "h-5 w-5",
  };

  const counterClassName = {
    sm: "!text-sm",
    md: "!text-md",
    lg: "!text-2xl",
  };

  return (
    <div className={clsx("flex w-full items-center justify-between gap-x-4 py-4", className)} data-testid="Counter">
      <div className="flex items-center justify-center gap-x-2">
        <div
          data-testid="subtract"
          onClick={() => value > min && onChange?.(value - step)}
          className={clsx(
            containerIconClassName,
            containerIconSizeClassName[size],
            "hover:border-gray-200 hover:text-gray-200 border-neutral-300 text-neutral-600"
          )}
        >
          <MinusIcon className={iconClassName[size]} />
        </div>
        <div className={clsx("text-neutral-800", counterClassName[size])}>{value}</div>
        <div
          onClick={() => value < max && onChange?.(value + step)}
          className={clsx(
            containerIconClassName,
            containerIconSizeClassName[size],
            "hover:border-gray-200 hover:text-gray-200 border-neutral-300 text-neutral-600"
          )}
        >
          <PlusIcon className={iconClassName[size]} />
        </div>
      </div>
    </div>
  );
};
Counter.propTypes = {
  value: PropTypes.number,
  step: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
  showLabel: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  size: PropTypes.string,
  image: PropTypes.string,
  badgeCount: PropTypes.number,
};

export default Counter;
