import cx from "classnames";

import Picture from "components/picture";
import Video from "components/video";
import LottieImage from "components/lottie-image";

const FlowMedia = ({ bigMedia, classNamePicture, classNameVideo, classNameLottie }) => {
  if (bigMedia?.picture) {
    return (
      <Picture picture={{ image: bigMedia.picture }} layout="fill" objectFit="cover" className={classNamePicture} />
    );
  }

  if (bigMedia?.video) {
    return <Video video={bigMedia.video} className={cx("w-full object-contain", classNameVideo)} autoPlay muted loop />;
  }

  if (bigMedia?.bigLottieFile) {
    return <LottieImage lottieFile={bigMedia?.bigLottieFile} width={null} height={null} className={classNameLottie} />;
  }

  return null;
};

export default FlowMedia;
