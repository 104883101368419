import { useEffect, useRef } from "react";
import { Dialog } from "@headlessui/react";
import { UilMultiply } from "@iconscout/react-unicons";
import IconButton from "@buildappeal/react-component-library/dist/IconButton";

import useModalClearFix from "hooks/useModalClearFix";
import SnapSlider from "components/snap-slider";
import BlockContent from "components/blocks/block-content";
import FlowMedia from "components/flow/flow-media";

const FlowModalSlider = ({ isOpen, onClose, sliders = [] }) => {
  const slidesRef = useRef([]);

  useModalClearFix();

  useEffect(() => {
    slidesRef.current = slidesRef.current?.slice(0, sliders.length);
  }, [sliders]);

  const handleClickSlider = (e) => {
    const clickInSlide = slidesRef.current.some((slideRef) => {
      return slideRef?.contains(e.target);
    });

    if (!clickInSlide) {
      onClose();
    }
  };

  if (!sliders?.length) return null;

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className="fixed inset-0 z-30 bg-black/70" />
      <div className="fixed inset-0 z-30 mx-0 flex items-center justify-center overflow-y-auto">
        <Dialog.Panel className="relative z-40 w-full">
          <SnapSlider
            className="z-50 scroll-ml-20 flex-wrap gap-x-7"
            itemClassName="lg:!w-full !w-[100vw] lg:w-[60vw] px-3.5 lg:px-0 lg:mx-3.5 !h-[80vh] lg:!h-[70vh] shrink-0 !snap-center snap-always"
            arrowClassName="z-[52]"
            childrenClassName="lg:px-[20vw]"
            dotsClassName="lg:bottom-[-50px]"
            dotColor="white"
            bgBlack={
              <div className="absolute inset-0 z-[51] hidden h-full w-full bg-black/30 px-0 lg:block lg:rounded-3xl" />
            }
            iconAvailableInActiveSlide={
              <IconButton
                icon={UilMultiply}
                color="white"
                className="absolute right-7 top-4 z-[52] border-0 lg:right-5"
                onClick={onClose}
              />
            }
            onClick={handleClickSlider}
            scrollByOneSlide
            showBgBlack
          >
            {sliders?.map((slider, index) => (
              <div
                className="relative flex w-full flex-col overflow-y-auto rounded-xl bg-white text-accent-700 lg:flex-row lg:gap-x-8 lg:overflow-hidden lg:rounded-3xl lg:pr-6"
                key={index}
                ref={(el) => (slidesRef.current[index] = el)}
              >
                <div className="relative z-50 min-h-[23rem] w-full overflow-hidden rounded-t-xl lg:w-[calc(100%_-_280px)] lg:rounded-l-3xl lg:rounded-tr-none">
                  <FlowMedia bigMedia={slider} classNamePicture="block" classNameVideo="w-full h-full" />
                </div>
                <div className="flex flex-col justify-center p-6 lg:w-[280px] lg:shrink-0 lg:p-0">
                  <h3 className="mb-5 text-2xl font-medium">{slider.title}</h3>
                  <BlockContent blocks={slider.text} className="hidden-list-icons !m-0 !text-sm" />
                </div>
              </div>
            ))}
          </SnapSlider>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default FlowModalSlider;
