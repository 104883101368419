import cx from "classnames";

import Picture from "components/picture";

const SIZE = {
  sm: "sm",
  lg: "lg",
};

const SpaceCircle = ({ space, image, className = "", hideName = false, size = SIZE.lg }) => {
  const sizeClassName = {
    [SIZE.sm]: "w-16 h-16",
    [SIZE.lg]: "h-[6rem] w-[6rem]",
  };

  return (
    <div data-cy="space-option" className="text-center">
      <div
        className={cx(
          "flex shrink-0 items-center justify-center rounded-full border border-neutral-300 bg-[#F5F1E4]",
          sizeClassName[size],
          className
        )}
      >
        <Picture
          height={36}
          width={36}
          layout="fixed"
          objectFit="contain"
          placeholder="empty"
          picture={{ image: image.asset }}
        />
      </div>
      {!hideName && <span className="text-center text-xs font-medium text-neutral-700">{space.spaceName}</span>}
    </div>
  );
};

export default SpaceCircle;
