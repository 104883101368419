import PropTypes from "prop-types";
import cx from "classnames";

import { formatCurrency } from "utils/helpers";

const SIZE = {
  sm: "sm",
  lg: "lg",
};

const Totals = ({ value, label, size = SIZE.sm, classNameValue = "", showBorder }) => {
  const sizeClassName = {
    [SIZE.sm]: "text-sm text-accent-700/70",
    [SIZE.lg]: "text-2xl font-medium text-accent-700",
  };

  return (
    <div className={cx("flex items-center justify-between py-2", showBorder && "border-b border-neutral-200")}>
      <span className="text-sm text-accent-700/70">{label}</span>
      <span className={cx(sizeClassName[size], classNameValue)}>{formatCurrency(value)}</span>
    </div>
  );
};

Totals.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.values(SIZE)),
};

export default Totals;
