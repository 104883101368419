import Image from "next/image";
import { useState } from "react";
import { UilMinusCircle } from "@iconscout/react-unicons";
import { UisPlusCircle } from "@iconscout/react-unicons-solid";

import CardLineItem from "./cart-line-item";
import calendarIcon from "public/icons/calendar.svg";

const OrderSummary = ({ className, spaces = [], services = [] }) => {
  const [showDetails, setShowDetails] = useState(false);

  const cardLinesSpaces = spaces.filter((cardLine) => cardLine?.services?.length > 0);

  return (
    <section className={className}>
      <header className="flex items-center justify-center gap-x-2 bg-[#F5F3EC] py-3">
        <Image alt="calendar icon" src={calendarIcon} width={24} height={23} unoptimized />
        <span className="text-sm font-medium text-accent-700">3-4 weeks to complete designs</span>
      </header>
      <div>
        {services.map((service) => (
          <CardLineItem
            key={`service-${service.id}`}
            title={service.slide.title}
            media={service.slide?.bigMedia}
            total={service.baseCost}
          />
        ))}
        {cardLinesSpaces.map((cardLine) => {
          const total = cardLine.services.reduce((acc, service) => {
            const baseCost = service.spaces?.find((space) => space.space.id === cardLine.space.id)?.baseCost || 0;

            return acc + baseCost;
          }, 0);

          return (
            <CardLineItem
              key={cardLine.uuid}
              title={`${cardLine.title} Designs`}
              total={total}
              items={cardLine.services.map((service) => ({
                subTitle: service.name,
                subTotal: service.spaces?.find((space) => space.space.id === cardLine.space.id)?.baseCost || 0,
              }))}
              showDetails={showDetails}
              space={{ image: cardLine.image }}
            />
          );
        })}
      </div>
      <footer className="flex items-center gap-x-2 py-3 px-2">
        {showDetails ? <UilMinusCircle className="h-4 w-4" /> : <UisPlusCircle className="h-4 w-4" />}
        <button className="text-sm text-accent-700 underline" onClick={() => setShowDetails(!showDetails)}>
          {showDetails ? "Hide Details" : "Show Details"}
        </button>
      </footer>
    </section>
  );
};

export default OrderSummary;
