import { useState } from "react";
import { UilAngleDown } from "@iconscout/react-unicons";
import noop from "lodash/noop";

import FlowModal from "./flow-modal";
import FlowTitleSummary from "./flow-title-summary";
import FlowSpaces from "./flow-spaces";
import PaymentSummary from "./payment-summary";
import OrderSummary from "./order-summary";

const FlowOverview = ({
  isOpen,
  onClose,
  spaces = [],
  spaceList = [],
  selectedServices = [],
  onShowEditSpaces = noop,
  total,
}) => {
  const [showSpaces, setShowSpaces] = useState(false);

  return (
    <FlowModal isOpen={isOpen} onClose={onClose}>
      <div className="lg:max-h-auto flex max-h-[85vh] flex-col overflow-y-auto lg:max-h-[100vh] lg:flex-row-reverse lg:overflow-y-hidden">
        <div className="flex flex-col items-center justify-center bg-[#EEEEE2] lg:w-[400px]">
          <div className="flex w-full items-center justify-between py-4 px-6 lg:justify-center">
            <span className="text-md font-medium lg:text-2xl">{spaceList?.length} spaces in your package</span>
            <UilAngleDown onClick={() => setShowSpaces(!showSpaces)} className="lg:hidden" />
          </div>
          <FlowSpaces spaces={spaceList} onEditClick={onShowEditSpaces} className={!showSpaces && "!hidden"} />
        </div>
        <div className="bg-aman px-6 py-6 lg:max-h-[70vh] lg:overflow-y-auto lg:py-16 lg:px-20">
          <FlowTitleSummary
            title="Your design package"
            description="Your package includes 1-on-1 white glove services from one of our expert designers and:"
            items={["2 design options", "2 revision rounds"]}
            className="mb-8"
          />
          <PaymentSummary className="mb-6" total={total} />
          <OrderSummary spaces={spaces} services={selectedServices} />
        </div>
      </div>
    </FlowModal>
  );
};

export default FlowOverview;
